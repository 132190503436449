import React, { Fragment } from "react"

import "../styles/projectTakeaway.scss"

import Contact from "../components/New-Contact-Form/new-contact-form"
import TestimonialBelt from "../components/Testimonial-Belt/testimonial-belt"

import { takeawayProject } from "../constants/new-ghost-projects"

import takeawayPoints from "../images/projects/takeaway-points.png"
import takeawayPoints2 from "../images/projects/takeaway-points-2.png"
import takeawayPunten from "../images/projects/punten.gif"
const ProjectTakeaway = () => {
  return (
    <Fragment>
      <div className="project-takeaway-title">
        {/* <h1>Takeaway Loyalty Points</h1> */}
      </div>
      <div className="project-takeaway-description">
        <p className="first-takeaway-paragraph">
          {takeawayProject.longDescription1}
        </p>
        <p>{takeawayProject.longDescription2}</p>
        <p>
          <a
            href="https://www.thuisbezorgd.nl/punten"
            target="_blank"
            rel="noopener noreferrer"
          >
            View the platform
          </a>
        </p>
      </div>
      <div className="project-takeaway-main-container">
        {/* <img src={takeawayPoints2} alt="takeaway-points" />
        <img src={takeawayPoints} alt="takeaway-points" /> */}
        <img src={takeawayPunten} alt="takeaway-points" />
      </div>
      <TestimonialBelt />
      <Contact />
    </Fragment>
  )
}

export default ProjectTakeaway
